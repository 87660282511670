<template>
  <div :style="getElementStyle">
    <component
      :is="selectedComponent"
      :data="this.selectedField"
      :isInDataTable="isInDataTable"
      @copy-document="copyDocument"
      :isDataTableField="isDataTableField"
      :fieldsData="fieldsData"
      v-on:settings="() => openSettings()"
      v-on:settingsDataTableColumn="
        (colData) => openSettingsForDataTable(colData)
      "
      v-on:configureColumn="(colData) => openTableColumnSettings(colData)"
      :style="getStyle"
    ></component>
    <span
      v-if="isActive || isDataTableField"
      class="setting-icon"
      @click="openSettings"
    >
      <i class="el-icon-s-tools" style="margin-right: 10px"></i>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import templateConstants from "@/constants/lg-en/templates";

import DateView from "../formComponentsView/DateView";
import TimeView from "../formComponentsView/TimeView";
import DateTimeView from "../formComponentsView/DateTimeView";
import DateTimeRangeView from "../formComponentsView/DateTimeRangeView";
import SingleLineTextView from "../formComponentsView/SingleLineTextView";
import MultiLineTextView from "../formComponentsView/MultiLineTextView";
import FileView from "../formComponentsView/FileView";
import MultiSelectView from "../formComponentsView/MultiSelectView";
import NumberView from "../formComponentsView/NumberView";
import SelectView from "../formComponentsView/SelectView";
import ListView from "../formComponentsView/ListView";
import YesOrNoView from "../formComponentsView/YesOrNoView";
import HeadingView from "../formComponentsView/HeadingView";
import CheckboxView from "../formComponentsView/CheckboxView";
import ESignatureView from "../formComponentsView/ESignatureView";

import GlobalVariableView from "../formComponentsView/GlobalVariableView";

import ParagraphView from "../formComponentsView/ParagraphView";
import SingleLineContentView from "../formComponentsView/SingleLineContentView";
import AuthorizedSignatureView from "../formComponentsView/AuthorizedSignatureView";
import ImageView from "../formComponentsView/ImageView";
import FormulaView from "../formComponentsView/FormulaView";
import ActionButtonView from "../formComponentsView/ActionButtonView";
import AggregateFunctionView from "../formComponentsView/AggregateFunctionView";

import HtmlView from "../formComponentsView/HtmlView";
import EntityView from "../formComponentsView/EntityView";
import CurrencyView from "../formComponentsView/CurrencyView.vue";
import PhoneCountryCodeView from "../formComponentsView/PhoneCountryCodeView.vue";
import RadioView from "../formComponentsView/RadioView.vue";
import WeekdaysView from "../formComponentsView/WeekDaysView";
import TimeRangeView from "../formComponentsView/TimeRangeView.vue";
import CheckBoxGroupView from "../formComponentsView/CheckBoxGroupView.vue";
import StarRatingView from "../formComponentsView/StarRatingView";
import DataTableView from "../formComponentsView/DataTableView";
import RadioButtonGroupView from "../formComponentsView/RadioButtonGroupView";
import DateRangeView from "../formComponentsView/DateRangeView";
import AutoIncrementView from "../formComponentsView/AutoIncrementView.vue";
import PayButtonView from "../formComponentsView/PaymentView.vue";
import ConcatenateView from "../formComponentsView/ConcatenateView";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import RandomTextView from '../formComponentsView/RandomTextView.vue';
import VideoView from "../formComponentsView/VideoView";
export default {
  name: "EntityVariableView",
  components: {
    //view

    DateView,
    TimeView,
    DateTimeView,
    FileView,
    MultiLineTextView,
    SingleLineTextView,
    MultiSelectView,
    NumberView,
    SelectView,
    ListView,
    YesOrNoView,
    HeadingView,
    CheckboxView,
    ESignatureView,
    GlobalVariableView,
    ParagraphView,
    SingleLineContentView,
    AuthorizedSignatureView,
    ImageView,
    FormulaView,
    ActionButtonView,
    HtmlView,
    AggregateFunctionView,
    EntityView,
    CurrencyView,
    PhoneCountryCodeView,
    RadioView,
    DateTimeRangeView,
    WeekdaysView,
    TimeRangeView,
    CheckBoxGroupView,
    StarRatingView,
    DataTableView,
    RadioButtonGroupView,
    DateRangeView,
    AutoIncrementView,
    PayButtonView,
    ConcatenateView,
    RandomTextView,
    VideoView,
  },
  props: [
    "data",
    "value",
    "isActive",
    "isDataTableField",
    "fieldsData",
    "isInDataTable",
    "allEntitiesData",
  ],
  mixins: [TemplateBuilderHelper],
  // Inside your component's code

  computed: {
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("companyTemplates", [
      "getSingleCompanyTemplate",
      "getTemplateDataTempVariable",
    ]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),
    ...mapGetters("entities", ["getAllEntitiesInTemplate"]),
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      this.data.width !== undefined ? this.data.width : this.data.min_width;
      return `height:${this.data.height - 30}px;` ;
      // width: ${this.data.width}px !important;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
    getAllUsers() {
      return this.getUsersList && this.getUsersList.data
        ? this.getUsersList.data
        : [];
    },
    getAllEntities() {
      return this.getAllEntitiesData && this.getAllEntitiesData.data
        ? this.getAllEntitiesData.data
        : [];
    },
    getPrimaryDataForUser() {
      return (user) => {
        let primaryFields = user.user_type.allowed_fields.filter(
          (e) => e.primary
        );

        let data = [];

        primaryFields.forEach((field) => {
          data.push(user[field.key]);
        });

        return data.join(" ");
      };
    },
    getPrimaryDataForEntity() {
      return (entity) => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach((field) => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });

        return data.join(" ");
      };
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      selectedEntity: {},
      selectedField: {},
      selectedEntityFields: [],
      selectedComponent: "",
      actionFieldData: [],
      entitiesData: [],
      paymentFields: [
        {
          label: "Transaction Id",
          key: "txn_id",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Gateway", key: "gateway", input_type: "SINGLE_LINE_TEXT" },
        { label: "Name", key: "name", input_type: "SINGLE_LINE_TEXT" },
        { label: "Email", key: "email", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Phone Number",
          key: "phoneNumber",
          input_type: "PHONE_COUNTRY_CODE",
        },
        {
          label: "Payment Type",
          key: "paymentType",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Method", key: "method", input_type: "SINGLE_LINE_TEXT" },
        { label: "Currency", key: "currency", input_type: "SINGLE_LINE_TEXT" },
        { label: "Amount", key: "amount", input_type: "CURRENCY" },
        { label: "Payment Date", key: "paymentDate", input_type: "DATE" },
        { label: "Status", key: "status", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Payment Session",
          key: "paymentSession",
          input_type: "SINGLE_LINE_TEXT",
        },
        {
          label: "Amount Refunded",
          key: "amountRefunded",
          input_type: "CURRENCY",
        },
        {
          label: "Description",
          key: "description",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Refund Date", key: "refundDate", input_type: "DATE" },
      ],
    };
  },
  mounted() {
    this.fetchOptions();
    this.options = this.data.options || [];
  },
  methods: {
    openTableColumnSettings() {
      // Fields can't be edited when data table as Entity variable
      // this.$emit("configureColumn", {...column});
    },
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettingsForDataTable(column) {
      this.$emit("settingsDataTableColumn", { ...column });
    },
    openSettings() {
      this.$emit("settings");
    },
    async fetchOptions() {
      let parentField = this.fieldsData && this.fieldsData.find(
        (e) => e.key == this.data.relationship_key
      );
      let selectedEntity = this.getAllEntitiesInTemplate[parentField?.entity_id];
      if (!selectedEntity) {
        selectedEntity = this.allEntitiesData?.find(
          (e) => e._id == parentField.entity_id
        );
      }
      try {
        if (this.data.global_variable_entity_field.includes("~")) {
          let relationshipId =
            this.data.global_variable_entity_field.split("~")[0];

          this.selectedField = {
            action_button_repeating_template_relationships: [],
            input_type: "ENTITY",
            inputType: "ENTITY",
            content: "",
            selected_fields: [],
            formula: "",
            function_type: "",
            validations: {
              multiple: false,
              maxFiles: 1,
              minFiles: 1,
              max_document_files: 1,
              min_document_files: 1,
              required: false,
              type: "OBJECT_ID",
              data_type: "OBJECT_ID",
            },
            defaultCountry: "US",
            countryCodeSelector: true,
            countryDisable: false,
            styles: {
              font: {
                name: "Helvetica",
                size: 16,
                style: 0,
                color: "#000000",
              },
              labelStyle: this.data.styles ? this.data.styles.labelStyle : "",
            },
            properties: {
              hideLabel: false,
              prefix: false,
              _id: "634d6ed8e51da443f3a2b7f9",
              is_unique: false,
              personal_data: false,
              personal_data_type: "",
              is_encrypted: false,
            },
            decimals: null,
            date_result_type: "",

            filled_by: "SENDER",
            type: "FIELD",

            is_global_variable: false,

            filters: [
              {
                field: "self#related_to/1",
                operator: "=",
                value: "",
                query_type: "AND",
                data_type: "RELATION",
                data_source: "self_field",
                value_field: this.data.parent_key,
                relation_entity_id: this.data.relationship_key,
              },
            ],
            entity_id: relationshipId,
            visibility: true,
          };
          this.setEntityFields();
        } else if (this.data.global_variable_entity_field) {
          let templateId = this.data.global_variable_entity_field.includes("#")
            ? this.data.global_variable_entity_field.split("#")[0]
            : this.data.global_variable_entity_field;
          let key = this.data.global_variable_entity_field.split("#")[1];

          if (templateId) {
            let tempData;
            if (
              this.getTemplateDataTempVariable &&
              this.getTemplateDataTempVariable[templateId]
            ) {
              tempData = JSON.parse(
                JSON.stringify(this.getTemplateDataTempVariable[templateId])
              );
            } else {
              await this.$store.dispatch(
                "companyTemplates/fetchSingleCompanyTemplate",
                templateId
              );
              tempData = this.getSingleCompanyTemplate;
            }

            if (tempData) {
              let selectedTemp = (selectedEntity?.templates || []).find(
                (e) => e.template_id == templateId
              );
              this.selectedEntityFields = this.applyCustomizationOnFields(
                this.getTemplateFields(tempData),
                selectedTemp?.customization || {}
              );
              let selectedField = this.selectedEntityFields.find(
                (f) => key == f.key
              );

              if (selectedField.inputType == "DATA_TABLE") {
                let mergedField = this.mergeObjects(this.data, selectedField, [
                  "properties",
                  "default_mapped_field",
                  "add_default_value",
                  "apply_default_at_end",
                ]);
                mergedField.data_table_columns =
                  mergedField.data_table_columns.map((el, i) => {
                    if (this.data?.data_table_columns?.[i]) {
                      el = this.mergeObjects(
                        this.data.data_table_columns[i],
                        el,
                        [
                          "properties",
                          "data_table_key",
                          "default_mapped_field",
                          "add_default_value",
                          "apply_default_at_end",
                          "x",
                          "y",
                          "width",
                          "height",
                        ]
                      );
                    }
                    return el;
                  });
                this.selectedField = { ...mergedField };
                let fieldIndex = this.fieldsData.findIndex(
                  (e) => e.key == this.data.key
                );
                if (fieldIndex > -1) {
                  this.fieldsData[fieldIndex]["data_table_columns"] =
                    this.selectedField.data_table_columns;
                  // this.$emit("updateFieldProperty", fieldIndex, {data_table_columns: this.selectedField.data_table_columns});
                }
              } else if (selectedField.inputType == "PAY_BUTTON") {
                if (this.data.global_variable_pay_field) {
                  let selectedFieldData = {};
                  selectedFieldData = this.paymentFields.find(
                    (field) => field.key == this.data.global_variable_pay_field
                  );
                  this.selectedField = {
                    ...selectedField,
                    inputType: selectedFieldData.input_type,
                  };
                  // if(this.field.global_variable_entity_field )
                  // {
                  // let splitValues= this.field.global_variable_entity_field.split("#");

                  // this.field.key=splitValues[1]+'_'+fieldId;
                  // this.selectedField.inputType = selectedFieldData.input_type;
                } else {
                  this.selectedField = { ...selectedField };
                }
              } else {
                this.selectedField = { ...selectedField };
              }
              this.setEntityFields();
            }
          }
        }

        this.selectedField.height = this.data.height;
        this.selectedField.width = this.data.width;
        if (this.data?.default_mapped_field) {
          this.selectedField.default_mapped_field =
            this.data.default_mapped_field;
        }
        if (this.data?.add_default_value) {
          this.selectedField.add_default_value = this.data.add_default_value;
        }
        if (this.data?.validations) {
          if (this.selectedField?.validations) {
            this.selectedField.validations["required"] =
              this.data.validations.required;
          } else {
            this.selectedField.validations = this.data.validations;
          }
        }
        // setTimeout(async () => {
        //   this.setEntityFields();
        // },2000);
      } catch (error) {
        console.log("fetchOptions", error);
      }
    },
    mergeObjects(obj1, obj2, excludedProperties) {
      const mergedObj = {};
      for (let prop in obj1) {
        mergedObj[prop] = obj1[prop];
      }
      for (let prop in obj2) {
        if (excludedProperties.includes(prop)) {
          continue;
        }
        mergedObj[prop] = obj2[prop];
      }

      return mergedObj;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    setEntityFields() {
      if (this.selectedField) {
        this.selectedField.label = this.data.label;
        this.selectedField.placeholder = this.data.placeholder;
        this.selectedField.description = this.data.description;
      }

      let fieldMap = {
        DATE: "DateView",
        FILE: "FileView",
        MULTI_LINE_TEXT: "MultiLineTextView",
        SINGLE_LINE_TEXT: "SingleLineTextView",
        MULTI_SELECT: "MultiSelectView",
        NUMBER: "NumberView",
        SELECT: "SelectView",
        LIST: "ListView",
        YES_OR_NO: "YesOrNoView",
        HEADING: "HeadingView",
        CHECKBOX: "CheckboxView",
        SIGNATURE: "ESignatureView",
        GLOBAL_VAIRLABE: "GlobalVariableView",
        PARAGRAPH: "ParagraphView",
        SINGLE_LINE_CONTENT: "SingleLineContentView",
        AUTHORIZED_SIGNATURE: "AuthorizedSignatureView",
        IMAGE: "ImageView",
        FORMULA: "FormulaView",
        ACTION_BUTTON: "ActionButtonView",
        HTML: "HtmlView",
        AGGREGATAION: "AggregateFunctionView",
        ENTITY: "EntityView",
        CURRENCY: "CurrencyView",
        PHONE_COUNTRY_CODE: "PhoneCountryCodeView",
        DATE_TIME: "DateTimeView",
        TIME: "TimeView",
        RADIO: "RadioView",
        DATE_TIME_RANGE: "DateTimeRangeView",
        DATE_RANGE: "DateRangeView",
        WEEKDAYS: "WeekdaysView",
        TIME_RANGE: "TimeRangeView",
        CHECKBOX_GROUP: "CheckBoxGroupView",
        AGGREGATE_FUNCTION: "AggregateFunctionView",
        STAR_RATING: "StarRatingView",
        DATA_TABLE: "DataTableView",
        RADIO_BUTTON_GROUP: "RadioButtonGroupView",
        AUTO_INCREMENT_NUMBER: "AutoIncrementView",
        PAY_BUTTON: "PayButtonView",
        CONCATENATE: "ConcatenateView",
        RANDOM_TEXT : "RandomTextView",
        VIDEO :"VideoView",
      };
      this.selectedComponent = fieldMap[this.selectedField.inputType];
    },
    async getCompoentForField(data) {
      try {
        let params = "form=true&content=true";
        await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.getFList) {
          let dFieldList = [
            ...this.getFList.allFormFields,
            ...this.getFList.allContentFields,
          ];
          let field = dFieldList.find((x) => x.inputType == data.input_type);
          if (field) {
            return field.components.view;
          }
          return "";
        }
      } catch (error) {
        console.log("getCompoentForField", error);
      }
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log("getFieldData", error);
      }
    },
  },
  watch: {
    "data.global_variable_entity_field": {
      async handler() {
        // do stuff
        await this.fetchOptions();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
    width: inherit;
  }
}
</style>
